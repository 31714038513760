import React, { useEffect, useState } from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from 'components/layout/layoutPanel';
import Image from 'components/common/Image';
import { modalContents } from "constants/modal-content";
import { LinkModal } from "kh-common-components";
import BlogCarousel from 'components/cards/BlogCarousel';

import flybuysX2 from 'images/flybuys/flybuys-2x.png';
import flybuysX5 from 'images/flybuys/flybuys-5x.png';

import logoCommunityCinema from "images/sponsorships/logo-communitycinemas.png";
import logoFringeWorld from "images/sponsorships/logo-fringeworld.png";
import logoPGolfStacked from "images/sponsorships/logo-pgolf-stacked.png";
import logoGfwsHeroBadge from "images/sponsorships/gfws-hero-badge-2.png";

function FlybuysOfferConfirmationPage() {

	const confirmationSettings = {
		'846': {
			banner : flybuysX2,
			title: 'Flybuys points muliplier applied',
			description: 'You\'ll now collect 2 Flybuys points for every $1 you spend on natural gas over the next four months.*',
			modalContentName: 'flybuys-2x-terms',
			multipleDisplay: "Double"
			
		},
		'638': {
			banner : flybuysX5,
			title: 'Flybuys points muliplier applied',
			description: 'You\'ll now collect 5 Flybuys points for every $1 you spend on natural gas over the next four months.*',
			modalContentName: 'flybuys-5x-terms',
			multipleDisplay: "5 x"
		},
		'unsuccessful': {
			banner : null,
			title: 'We were unable to apply the promotion to your account.',
			description: null,
			modalContentName: null
		}
	}

	const [promo, setPromo] = useState('');
	
	useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const promoQuery = urlParams.get('promo');

		if (process.env.FLYBUYS_MULTIPLIER === 'true')
		{
			if (promoQuery !== null && confirmationSettings[promoQuery.toLocaleLowerCase()] !== undefined) {
				setPromo(promoQuery.toLocaleLowerCase());
			}
			else {
				setPromo('unsuccessful');
			}
		}
		else
		{
			setPromo('unsuccessful');
		}
		
    }, []);
	
	return (
		<>
			<SEO title="Flybuys multiplier offers | Kleenheat" 
            	 description="You'll now collect 2 Flybuys ponts for every $1 you spend on naturual gas over the next four months.*"
                 meta={[{name:"robots", content:'noindex'}]}
        	/>
			<Header />
			<LayoutMain>
				<LayoutPanel theme="mer-theme--cyan"
							 background="mer-bg--primary-cyan"
							 padding="mer-py-lg">
					<div className="container">
						<div className="row mer-panel align-items-center justify-content-around flex-lg-column">
							{promo !== '' && confirmationSettings[promo].banner !== null &&
								<div className="mer-panel__images col-6 col-sm-4 col-lg-3 col-xl-3 my-0 pt-0">
									<img src={confirmationSettings[promo].banner} 
										className="mer-panel__image"
										style={{border: '.25em solid #ffffff', borderRadius: '100%'}}
										alt={`Flybuys ${promo} points`} />
								</div>
							}

							<div className="col-12 col-sm-10 pt-0 text-center mer-text--size-lg">
								{promo !== '' && confirmationSettings[promo].title !== null &&
									<h2 className="mer-panel__title mer-typography--headline2 my-0">{confirmationSettings[promo].title}</h2>
								}
								{promo !== '' && confirmationSettings[promo].description !== null &&
									<div className="row align-items-center justify-content-center">
										<p className="col-11 col-sm-8 col-lg-6 col-xl-6 pt-0 pb-0">{confirmationSettings[promo].description}</p>
									</div>
								}

								{promo !== '' && confirmationSettings[promo].modalContentName !== null &&
									<small className="text-center">
										<LinkModal 
											modalContents={modalContents}
											useModal="true"
											title={`${confirmationSettings[promo].multipleDisplay} Flybuys Points Offer terms & conditions`}
											linkClass="mer-text--no-wrap"
											displayContent="*Terms and conditions apply"
											modalTitle={`${confirmationSettings[promo].multipleDisplay} Flybuys Points Offer terms & conditions`}
											modalContentName={confirmationSettings[promo].modalContentName}
											okayButtonLabel="Okay"
										/>
									</small>
								}
							</div>

						</div>
					</div>
				
				</LayoutPanel>

				<section className="module-wrapper mer-theme--light mer-bg--ui-light mer-pt-lg">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-10 col-sm-7 col-lg-6">
								<div className="content text-center">
									<h2>Sponsorships</h2>
									<p>We are proud to support the local WA events you love, and offer special benefits to our customers.</p>
								</div>
							</div>
							<div className="col-10 col-sm-12 col-lg-8">
								<div className="row align-items-center justify-content-center">
									<div className="col-5 col-sm-3 mer-p-de">
										<a href="/about/community/telethon-community-cinemas" title="Open Telethon Community Cinemas page" class="mer-link--hover-none">
											<img className="mer-panel__image"
												 src={logoCommunityCinema}
												 alt="Telethon Community Cinemas"
											/>
										</a>
									</div>
									<div className="col-5 col-sm-3 mer-p-de">
										<a href="/about/community/fringe-world-festival" title="Open Fringe World Festival page" class="mer-link--hover-none">
											<img className="mer-panel__image"
												 src={logoFringeWorld}
												 alt="Fringe World"
											/>
										</a>
									</div>
									<div className="col-5 col-sm-3 mer-p-de">
										<a href="/about/community/perth-garden-festival" title="Open Perth Garden &amp; Outdoor Living Festival page" class="mer-link--hover-none">
											<img className="mer-panel__image"
												 src={logoPGolfStacked}
												 alt="Perth Garden Festival"
											/>
										</a>
									</div>
									<div class="col-5 col-sm-3 mer-p-de">
										<a href="/about/community/good-food-wine-show" title="Open Good Food Wine Show page" class="mer-link--hover-none">
											<img className="mer-panel__image"
											       src={logoGfwsHeroBadge}
												   alt="Good Food & Wine Show"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="module-wrapper mer-theme--light mer-bg--ui-light mer-py-lg">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-10 col-sm-7 col-lg-6">
							<div className="content text-center">
									<h2>Partnerships</h2>
									<p>We focus on providing ongoing customer value and benefits through our current partnerships.</p>
								</div>
							</div>
							<div className="col-10 col-sm-12 col-lg-8">
								<div className="row align-items-center justify-content-center">
									<div className="col-5 col-sm-3 mer-p-de">
										<a href="/rac" title="Open Kleenheat RAC page" class="mer-link--hover-none">
											<Image className="mer-panel__image" src="/logo/rac.png" alt="RAC logo" />
										</a>
									</div>
									<div className="col-5 col-sm-3 mer-p-de">
										<a href="/help/rewards-offers/value-flybuys-kleenheat" title="Open Kleenheat Flybuys page" class="mer-link--hover-none">
											<Image className="mer-panel__image" src="/logo/flybuys.png" alt="flybuys logo" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<BlogCarousel id="stories"
							title="Our latest community activity"
							titleCol="col-10"							
							apiQuery="?tag=community&posts_per_page=5"
							ctaContainerClass="row justify-content-center"
							ctaClass="col-10 mer-button-group align-items-center justify-content-center p-0"
							ctaHeadingClass='mer-typography--headline5'
							ctaText="View more community articles"
			              	ctaLink="/blog/community"
							ctaLinkClass="mer-button mer-button--primary"
							hidePanelImgBg="true"
							panelBg="mer-theme--light mer-bg--gs-grey-lighter"
				/>

			</LayoutMain>
			<Footer />
		</>
	)
}

export default FlybuysOfferConfirmationPage;